/*
 * Copyright 2023 Scott Weeden-Moody
 */

.navigation-links {
  margin-top: 16px;
}

.navigation-links a {
  text-decoration: none;
}

.navigation-links img.first {
  margin-left: 12px;
}

.navigation-links img {
  padding: 12px;
}
