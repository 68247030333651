/*
 * Copyright 2023 Scott Weeden-Moody
 */

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* 'Compact width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
:root {
  --default-side-padding: 48px;
  --default-top-padding: 24px;

  --navigation-side-padding: 24px;

  --accent-color: #CA8A04; // Tailwind Yellow 600
  --primary-color: #18181B; // Tailwind Zinc 900
  --secondary-color: #3F3F46; // Tailwind Zinc 700

  --text-color-dark: #000000; // Black
  --text-color-light: #FFFFFF; // White
}

html, body {
  height: 100%;
}

body {
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Courier New", monospace
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color-light);
}

a {
  color: var(--accent-color);
}

li, p {
  color: var(--text-color-light);
}

.content {
  flex-wrap: wrap;
  padding-left: var(--default-side-padding);
  padding-right: var(--default-side-padding);
}

/* 'Medium width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 600px) {
}

/* 'Expanded width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 840px) {
  body {
    flex-direction: row;
  }

  .content {
    padding-top: var(--default-top-padding);
  }
}
