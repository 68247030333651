/*
 * Copyright 2023 Scott Weeden-Moody
 */

/* 'Compact width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
.app-bar {
  display: flex;
  flex: 0 0 56px;
  flex-direction: row;
}

.app-bar h3 {
  margin-left: 32px;
}

#navigation-menu-toggle {
  align-self: flex-start;
  background: none;
  border: none;
  margin-left: 16px;
  margin-top: 16px;
}

/* 'Medium width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 600px) {
}

/* 'Expanded width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 840px) {
  .app-bar {
    display: none;
  }
}
