/*
 * Copyright 2023 Scott Weeden-Moody
 */

/* 'Compact width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
.navigation {
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 75%;
  position: fixed;
  transform: translateX(-100%);
  visibility: hidden;
}

.navigation.expanded {
  animation: slide-in 0.5s both;
}

.navigation.hidden {
  animation: slide-out 0.5s both;
}

.navigation h1 {
  padding-left: var(--navigation-side-padding);
  padding-right: var(--navigation-side-padding);
}

.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navigation li a {
  display: block;
  color: var(--text-color-light);
  padding-bottom: 8px;
  padding-left: var(--navigation-side-padding);
  padding-right: var(--navigation-side-padding);
  padding-top: 8px;
  text-decoration: none;
}

.navigation li a:hover {
  background-color: var(--accent-color);
  color: var(--text-color-light);
}

@keyframes slide-in {
  100% { 
    transform: translateX(0%);
    visibility: visible; 
  }
}

@keyframes slide-out {
  0% { 
    transform: translateX(0%);
    visibility: visible; 
  }
  99% {
    visibility: visible;
  }
  100% { 
    transform: translateX(-100%);
    visibility: hidden; 
  }
}

/* 'Medium width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 600px) {
}

/* 'Expanded width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 840px) {
  .navigation {
    flex: 0 0 auto;
    position: static;
    transform: none;
    visibility: visible;
  }

  .navigation.expanded {
    animation: none;
  }

  .navigation.hidden {
    animation: none;
  }
}
