/*
 * Copyright 2023 Scott Weeden-Moody
 */

.navigation-header {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: var(--default-top-padding);
}

.navigation-header a {
  text-decoration: none;
}

#navigation-menu-close {
  align-self: flex-start;
  background: none;
  border: none;
  margin-left: 16px;
  margin-top: 16px;
  position: fixed;
}

/* 'Medium width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 600px) {
}

/* 'Expanded width' category; see https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes */
@media screen and (min-width: 840px) {
  #navigation-menu-close {
    display: none;
  }
}
