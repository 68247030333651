/*
 * Copyright 2023 Scott Weeden-Moody
 */

.navigation-footer {
  margin-top: auto;
  padding-left: var(--navigation-side-padding);
  padding-right: var(--navigation-side-padding);
}

.navigation-footer p {
  font-size: 0.8em;
}
